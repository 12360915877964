import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import {
  isUserLoggedIn, getUserData, getUserRole, getHomeRouteForLoggedInUser,
} from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'login' } },
    {
      path: '/admin',
      meta: {
        needLogin: true,
      },
      beforeEnter: (to, from, next) => {
        store.dispatch('auth/checkToken')
        next()
      },
      component: () => import('@/layouts/full/LayoutFull.vue'),
      children: [
        {
          path: '',
          name: 'dashboard',
          component: () => import('@/views/Home.vue'),
        },
        {
          path: 'profile',
          name: 'profile',
          component: () => import('@/views/account/AccountPage.vue'),
        },
        {
          path: 'projects',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'projects',
          component: () => import('@/views/projects/List.vue'),
        },
        {
          path: 'projects/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'editProject',
          component: () => import('@/views/projects/Edit.vue'),
        },
        {
          path: 'projects/view/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'viewProject',
          component: () => import('@/views/projects/View.vue'),
        },
        {
          path: 'projects/create/:client_param?',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'createProject',
          component: () => import('@/views/projects/Create.vue'),
        },
        {
          path: 'tasks',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'tasks',
          component: () => import('@/views/tasks/List.vue'),
        },
        {
          path: 'tasks/view/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'viewTask',
          component: () => import('@/views/tasks/View.vue'),
        },
        {
          path: 'jobs',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'jobs',
          component: () => import('@/views/jobs/List.vue'),
        },
        {
          path: 'jobs/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'editJob',
          component: () => import('@/views/jobs/Edit.vue'),
        },
        {
          path: 'jobs/view/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'viewJob',
          component: () => import('@/views/jobs/View.vue'),
        },
        {
          path: 'jobs/create/:project_param?',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'createJob',
          component: () => import('@/views/jobs/Create.vue'),
        },
        {
          path: 'users/operarios',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'operarios',
          component: () => import('@/views/users/Empleados.vue'),
        },
        {
          path: 'users',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'users',
          component: () => import('@/views/users/List.vue'),
        },
        {
          path: 'users/access-log',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'users',
          component: () => import('@/views/users/AccessLog.vue'),
        },
        {
          path: 'users/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'editUser',
          component: () => import('@/views/users/Edit.vue'),
        },
        {
          path: 'users/view/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'viewUser',
          component: () => import('@/views/users/View.vue'),
        },
        {
          path: 'users/create',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa', 'admin_cliente'],
          },
          name: 'createUser',
          component: () => import('@/views/users/Create.vue'),
        },
        {
          path: 'categories',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'categories',
          component: () => import('@/views/categories/List.vue'),
        },
        {
          path: 'categories/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'editCategory',
          component: () => import('@/views/categories/Edit.vue'),
        },
        {
          path: 'categories/create',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa', 'admin_cliente'],
          },
          name: 'createCategory',
          component: () => import('@/views/categories/Create.vue'),
        },
        {
          path: 'traceabilities',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'traceabilities',
          component: () => import('@/views/traceabilities/List.vue'),
        },
        {
          path: 'machines',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'machines',
          component: () => import('@/views/machines/List.vue'),
        },
        {
          path: 'machines/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'editMachine',
          component: () => import('@/views/machines/Edit.vue'),
        },
        {
          path: 'machines/create',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa', 'admin_cliente'],
          },
          name: 'createMachine',
          component: () => import('@/views/machines/Create.vue'),
        },
        {
          path: 'departments',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'departments',
          component: () => import('@/views/departments/List.vue'),
        },
        {
          path: 'departments/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'editDepartment',
          component: () => import('@/views/departments/Edit.vue'),
        },
        {
          path: 'departments/create',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'createDepartment',
          component: () => import('@/views/departments/Create.vue'),
        },
        {
          path: 'clients',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'clients',
          component: () => import('@/views/clients/List.vue'),
        },
        {
          path: 'clients/view/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'viewClient',
          component: () => import('@/views/clients/View.vue'),
        },
        {
          path: 'clients/edit/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'editClient',
          component: () => import('@/views/clients/Edit.vue'),
        },
        {
          path: 'clients/create',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'createClient',
          component: () => import('@/views/clients/Create.vue'),
        },
        {
          path: 'partes/operario',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'partesOperario',
          component: () => import('@/views/partes/Operario.vue'),
        },
        {
          path: 'partes/obras',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'partesObras',
          component: () => import('@/views/partes/Obras_clientes.vue'),
        },
        {
          path: 'partes/obras/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'partesObrasClient',
          component: () => import('@/views/partes/Obras.vue'),
        },
        {
          path: 'partes/obras/jobs/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'partesObrasJobs',
          component: () => import('@/views/partes/Obras_trabajos.vue'),
        },
        {
          path: 'partes/obras/journeys/:id',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'partesObrasJourneys',
          component: () => import('@/views/partes/Obras_jornadas.vue'),
        },
        {
          path: 'calendario',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'calendario',
          component: () => import('@/views/calendario/Calendario.vue'),
        },
        {
          path: 'config',
          meta: {
            needLogin: true,
            roleAllow: ['super_admin', 'admin_empresa'],
          },
          name: 'config',
          component: () => import('@/views/configuracion/Configuracion.vue'),
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-401',
      name: 'error-401',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (to.meta.needLogin) {
    if (!isLoggedIn) { return next({ name: 'login' }) }
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn === true && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData))
  }
  if (to.meta.roleAllow) {
    const role = getUserRole()
    if (!to.meta.roleAllow.includes(role)) {
      next({ name: 'error-401' })
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
